import { AddAssuranceError } from "./AddAssuranceError";

import { z } from "zod";

export const AddAssuranceSchema = z.object({
   nomSociete: z.string(),
   service: z.string(),
   adresse: z.string(),
   complementAdresse: z.string(),
   ville: z.string(),
   codePostal: z.string(),
   email: z.string(),
});

export type ErreurAddAssuranceType = {
    error: boolean,
    detail: AddAssuranceError
};