export const text = {
    components: {
        drawer: {
            lienListeUtilisateur: "Liste utilisateurs",
            titleIconUtilisateur: "Icon du lien liste des utilisateurs",
            lienListeProduit: "Liste produits",
            titleIconProduit: "Icon du lien liste des produits",
            lienListeAssurance: "Liste assurances",
            titleIconAssurance: "Icon du lien liste des assurances"
        },
        tableauUtilisateur: {
            msgErrNewPage: "Une erreur concernant la récupération de la page demandée est survenue, contactez votre administrateur"
        },
        ligneUtilisateur: {
            labelModalConfirm: "Je confirme la suppression du compte",
        },
        modalAjouterUnUtilisateur: {
            msgSuccessCreate: "Le compte utilisateur a bien été créer",
            msgErrCreate: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            labelBtnOpenModal: "Ajouter un utilisateur",
            titlePortal: "Ajouter un utilisateur",
            labelNomSociete: "Nom de société",
            labelEmail: "Adresse e-mail",
            labelPassword: "Mot de passe",
            labelConfirmPassword: "Confirmer mot de passe",
            labelBtnAjouter: "Ajouter",
            labelBtnAnnuler: "Annuler"
        },
        modalEnvoyerUnEmail: {
            titlePortal: "Contacter un utilisateur",
            labelEmetteur: "De:",
            labelDestinataire: "A:",
            labelPieceJointe: "Joindre un fichier",
            placeholderObjetEmail: "Objet",
            labelBtnEnvoyer: "Envoyer",
            labelBtnAnnuler: "Annuler"
        },
        modaleUtilisateur: {
            titlePortal: "Compte utilisateur",
            labelCreationCompte: "Création du compte:",
            labelQuiCreationCompte: "Par:",
            labelQuandCreationCompte: "Le:",
            labelmiseAjourCompte: "Dernière modification du compte:",
            labeQuilmiseAjourCompte: "Par:",
            labeQuandlmiseAjourCompte: "Le:",
            labelNomSociete: "Nom de la société",
            labelEmail: "Adresse e-mail",
            labelNomDirigeant: "Nom du dirigeant",
            labelPrenomDirigeant: "Prénom du dirigeant",
            labelSiret: "Numéro siret de la société",
            labelNumTva: "Numéro de TVA de la société",
            labelCapital: "Capital de la société",
            labelTelephone: "Numéro de téléphone de la société",
            labelcreditAbonnement: "Nombre de dossier de l'abonnement",
            labelcreditConsoDuMois: "Nombre de dossier créé dans le mois",
            labelcreditConsoGlobal: "Nombre de dossier créé total",
            labelDateEditionKbis: "Date d'édition du KBIS",
            labelDateExpireKbis: "Date d'expiration du KBIS",
            labelTauxHoraireHt: "Taux horaire HT en € ",
            msgSuccessCreate: "Le compte a bien été mis à jour",
            msgErrDisplay: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            labelBtnAnnuler: "Annuler",
            labelBtnValider: "Valider les modifications",
            labelBtnModifier: "Modifier les informations",
            labelBtnDesactive: "Désactiver le compte",
            labelBtnActive: "Activer le compte",
        },
        ligneProduit: {
            labelModalConfirm: "Je confirme la suppression du produit",
        },
        modaleProduit: {
            titlePortal: "Produit Intégré directement aux factures",
            labelBtnAnnuler: "Annuler",
            labelBtnValider: "Enregistrer",
            labelBtnModifier: "Modifier les informations",
            msgErrDisplay: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            msgSuccessCreate: "Le produit a bien été mis à jour",
            labelNomProduit: "Nom du produit",
            labelCodeProduit: "Reference du produit",
            labelTarifHt: "Tarif HT en €",
            labeltax: "TVA en %",
            labelQuantity: "Quantité",
            labelIntegration: "Intégré automatiquement ce produit aux factures",
        },
        modaleAjouterProduit: {
            titlePortal: "Ajouter un produit Intégré directement aux factures",
            labelBtnAjouter: "Ajouter un produit",
            labelBtnCreer: "Enregistrer",
            labelBtnAnnuler: "Annuler",
            msgErrDisplay: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            msgSuccessCreate: "Le produit a bien été enregistrer",
            labelNomProduit: "Nom du produit",
            labelCodeProduit: "Reference du produit",
            labelTarifHt: "Tarif HT en €",
            labeltax: "TVA en %",
            labelQuantity: "Quantité",
            labelIntegration: "Intégré automatiquement ce produit aux factures",
        },
        modaleAssurance: {
            titlePortal: "Coordonnées des assurances",
            labelBtnAnnuler: "Annuler",
            labelBtnValider: "Enregistrer",
            labelBtnModifier: "Modifier les informations",
            msgErrDisplay: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            msgSuccessUpdate: "L'assurance a bien été mis à jour",
            labelNomSciete: "Nom de la socété",
            labelService: "Nom du service",
            labelAdresse: "Adresse",
            labelComplAdresse: "Complément d'adresse",
            labelCodePostal: "code postal",
            labelVille: "Ville",
            labelEmail: "Adresse e-mail",
        },
        modaleAjouterAssurance: {
            titlePortal: "Ajouter une assurance",
            labelBtnAjouter: "Ajouter une assurance",
            labelBtnAnnuler: "Annuler",
            labelBtnModifier: "Enregistrer",
            msgErrDisplay: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            msgSuccessCreate: "L'assurance a bien été enregistrer",

            labelNomSciete: "Nom de la socété",
            labelService: "Nom du service",
            labelAdresse: "Adresse",
            labelComplAdresse: "Complément d'adresse",
            labelCodePostal: "code postal",
            labelVille: "Ville",
            labelEmail: "Adresse e-mail",
        },
    },
    pages: {
        login: {
            subtitle: "Bienvenue sur l'espace",
            mdpOublie: "Mot de passe oublié?",
            msgErrCredencials: "L'identifiant ou le mot de passe sont incorrects",
            msgErrValideToken: "Une erreur d'identification est survenue, veuillez réessayer ultérieurement",
            msgErrDecodeToken: "Une erreur concernant les données de votre compte utilisateur est survenue, contactez votre administrateur",
            msgErrUnauthorized: "Vous n'avez pas accès à cette ressource",
            msgErrServer: "Une erreur et survenue veuillez réessayer dans un ultérieurement",
            placeHolderEmail: "Adresse e-mail",
            placeHolderPassword: "Mot de passe",
            labelBtnConnexion: "Connexion"
        },
        listeUtilisateur: {
            title: "Liste des utilisateurs",
            msgErrDataSearchUser: "Une erreur concernant la récupération des données est survenue, contactez votre administrateur"
        },
        ajouterUtilisateur:{
            title: "Ajouter un utilisateur",
            labelEmail: "Adresse e-mail",
            labelPassword: "Mot de passe",
            labelConfirmPassword: "Confimer le mot de passe",
            labelNomSociete: "Nom de société",
            labelNomDirigeant: "Nom du dirigeant",
            labelPrenomDirigeant: "Prénom du dirigeant",
            labelSiret: "N° siret",
            labelNumTva: "N° de TVA",
            labelCapital: "Capital",
            labelTelephone: "N° de téléphone",
            labelUrlVosFacture: "URL du compte VosFactures",
            labelCodeApiVosFacture: "Code API du compte VosFactures",
            labelTauxHoraireHt: "Taux horaire HT en € ",
            labelDateEditionKbis: "Date d'édition du KBIS",
            labelFileKbis: "Extrait de KBIS mois de 3 mois",
            labelFileCachet: "Cachet signé de la scoiété",
            labelFileRib: "RIB de la scoiété",
            labelFileCni: "Carte d'identité du dirigeant",
            labelcreditAbonnement: "Nb dossier abonnement",
            labelBtnCreer: "Enregister",
            labelBtnReset: "Annuler",
        },
        utilisateur: {
            title: "Compte utilisateur",
            labelCreationCompte: "Création du compte:",
            labelQuiCreationCompte: "Par:",
            labelQuandCreationCompte: "Le:",
            labelmiseAjourCompte: "Dernière modification du compte:",
            labeQuilmiseAjourCompte: "Par:",
            labeQuandlmiseAjourCompte: "Le:",
            labelNomSociete: "Nom de la société",
            labelEmail: "Adresse e-mail",
            labelNomDirigeant: "Nom du dirigeant",
            labelPrenomDirigeant: "Prénom du dirigeant",
            labelSiret: "N° siret",
            labelNumTva: "N° de TVA",
            labelCapital: "Capital",
            labelTelephone: "N° de téléphone",
            labelcreditAbonnement: "Nb dossier abonnement",
            labelcreditConsoDuMois: "Nb dossier du mois",
            labelcreditConsoGlobal: "Nb dossier total",
            labelDateEditionKbis: "Date d'édition du KBIS",
            labelDateExpireKbis: "Date d'expiration du KBIS",
            labelTauxHoraireHt: "Taux horaire HT en € ",
            msgSuccessCreate: "Le compte a bien été mis à jour",
            msgErrDisplay: "Une erreur concernant la récupération des données demandée est survenue, contactez votre administrateur",
            labelBtnAnnuler: "Annuler",
            labelBtnValider: "Valider les modifications",
            labelBtnModifier: "Modifier les informations",
        },
        listeProduit: {
            title: "Liste des Produits",
            msgErrDataSearchUser: "Une erreur concernant la récupération des données est survenue, contactez votre administrateur"
        },
        listeAssurance: {
            title: "Liste des assurances",
            msgErrDataSearchUser: "Une erreur concernant la récupération des données est survenue, contactez votre administrateur"
        }
    },

};