import { Navigate } from 'react-router-dom';
import {  useAuth } from "../../../contexts/authContext/AuthContext";
import { ROLE_ADMIN } from "../../../shared/constants/Constatnts";

const AuthRoute = ({ Component }: {Component: any}) => {

    const {connected, role} = useAuth().isAuthenticated;

    return (connected === true && role === ROLE_ADMIN) ? <Component /> : <Navigate to = '/login' />
};

export default AuthRoute;
