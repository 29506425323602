import { styled } from "styled-components";
import { theme } from "../../../../../../../theme/theme";

export const BoxBtnAction = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const BoxBtnCancelAndValide = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SeparatoBtnAction = styled.div`
    width: 20px;
`;