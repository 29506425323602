
import { UpdateProduitError } from "./UpdateProduitError";
import { ErreurUpdateProduitType, UpdateProduitSchema, UpdateProduitType } from "./updateProduitValidationType";

export const updateProduitValidation = (data: UpdateProduitType): ErreurUpdateProduitType => {

    const erreurPage = new UpdateProduitError();

    const erreurUpdateProduitFinal: ErreurUpdateProduitType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = UpdateProduitSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurUpdateProduitFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: true });
        });

        return erreurUpdateProduitFinal;
    };

    return erreurUpdateProduitFinal;
};