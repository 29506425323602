export class UpdateProduitError {
    id: boolean;
    name: boolean;
    tax: boolean;
    code: boolean;
    totalPriceGross: boolean;
    totalPriceHt: boolean;
    quantity: boolean;
    createdAt: boolean;
    updateAt: boolean;
    invoiceIntegration: boolean;

    constructor() {
        this.id = false;
        this.name = false;
        this.tax = false;
        this.code = false;
        this.totalPriceGross = false;
        this.totalPriceHt = false;
        this.quantity = false;
        this.createdAt = false;
        this.updateAt = false;
        this.invoiceIntegration = false;
    };
};