import { styled } from "styled-components";
import { theme } from "../../../theme/theme";

export const BoxAjouterUtilisateur = styled.div`
   width: 70%;
   height: calc(100% - 100px);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start
   @media screen and (${theme.screen.laptopL}) {
      width: 95%;
      min-height: 100vh;
   };

   @media screen and (${theme.screen.tablette}) {
      width: 95%;
   };
`;

export const BoxHeadingAjouterUtilisateur = styled.div`
   width: 100%;
   height: 60px;
   margin: 15px 0 0 0;
   padding-bottom: 20px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   border-bottom: solid 2px ${theme.color.headerTable};
`;

export const HeadingAjouterUtilisateur = styled.h2`
   color: ${theme.color.primary};
   font-size: ${theme.font.xlarge};
`;

export const FormAjouterUtilisateur = styled.form`
    width: 100%;
    height: fit-content;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 25px;
    border-top: solid 1px ${theme.color.headerTable};
    @media screen and (${theme.screen.tablette}) {
        width: 90vw;
        max-height: 90vh;
        padding-top: 20px;
    };
`;

export const BoxFieldAjouterUtilisateur = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;


export const BoxBtnAjouterUtilisateur = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const SeparatorAjouterUtilisateur = styled.div`
    width: 20px;
`;

export const InputDocument = styled.input`
   width: 32%;
   color: ${theme.color.letterGray};
   padding: 7px 12px;
   background-color: ${theme.color.white};
   border: 1px solid ${theme.color.grayBorder};
   border-radius: 5px;
   font-size: 15px;
   &::file-selector-button{
      margin-right: 8px;
      padding: 2px 12px;
      font-size: 15px;
      background-color: ${theme.color.primary};
      color: ${theme.color.white};
      border-radius: 5px;
      border: 1px solid ${theme.color.primary};
      transition: opacity 0.2s;
      &:hover {
         cursor: pointer;
         opacity: 0.6;
      }
   }
`;