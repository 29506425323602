
import { AddProduitClass } from "../../../../shared/class/produit/addProduit/AddProduitClass";
import { AddProduitError } from "./AddProduitError";
import { AddProduitSchema, ErreurAddProduitType } from "./addProduitValidationType";

export const addProduitValidation = (data: AddProduitClass): ErreurAddProduitType => {

    const erreurPage = new AddProduitError();

    const erreurAddProduitFinal: ErreurAddProduitType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = AddProduitSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurAddProduitFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: true });
        });

        return erreurAddProduitFinal;
    };

    return erreurAddProduitFinal;
};