import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { theme } from "../../theme/theme";
import Button from "../../components/common/button/Button";

export const Main = styled.main`
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.theme.color.secondary};
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: center;
   
`;
export const Form = styled.form`
    width: 440px;
    height: 500px;
`;

export const HeadingForm = styled.p`
    width: 100%;
    height: fit-content;
    color: ${props => props.theme.color.letterGray};
    text-align: left;
    font-size: ${props => props.theme.font.xlarge};
    font-family: Montserrat-Regular;
    margin-bottom: 15px;
`;

export const BoxInputLogin = styled.div`
    height: 100px;
    margin: 30px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const BoxBtnLogin = styled.div`
    height: 65px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const LinkPassword = styled(Link)`
    height: ${props => props.theme.font.regular};
    color: ${props => props.theme.color.primary};
    font-size: ${props => props.theme.font.regular};
`;

export const ErrorForm = styled.span`
    width: 100%;
    min-height: ${props => props.theme.font.regular};
    text-align: left;
    font-size: ${props => props.theme.font.regular};
    color: ${props => props.theme.color.alert};
`;

