import styled from "styled-components";
import { theme } from "../../../theme/theme";

type InputProps = {
    $width?: string,
    $invalid?: boolean
};

export const BoxInput = styled.div<InputProps>`
    width: ${props => props.$width ? `${props.$width}%` : `440px`};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
     @media screen and (${theme.screen.tablette}) {
        width: 100%;
        &:first-child{
            margin-bottom: 25px;
        }
    };
`;

export const BoxLabelTooltips = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px; 
`;

export const Label = styled.label`
    color: ${theme.color.primary}
`;

export const Input = styled.input<InputProps>`
    width: 100%;
    height: 40px;
    border: solid 1px ${theme.color.grayBorder};
    border-radius: ${theme.css.radius};
    background-color: ${theme.color.white};
    padding: 0 10px;
    font-family: Montserrat-Regular;
    font-size: ${theme.font.regular};
    outline: none;
    ${props => props.$invalid && `border: solid 1px ${theme.color.alert};`}
    &: focus{
        border: solid 1px ${theme.color.primary};
    }
`;