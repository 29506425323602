
import { z } from "zod";
import { AddUserError } from "./AddUserError";
import { regexPassword, regexTauxHoraire, regexTelephone } from "../../../../shared/regex/regex";

const messageFormat = { message: 'Le format est incorrect' };
const messagePassword = { message: 'Le nouveau mot de passe n\'est pas conforme' };
const messageTelephone = { message: 'Le numero de téléphone est composé de 10 chiffres' };
const messageConfirmPassword = { message: 'Le mot de passe de confimation n\'est pas conforme' };

export const AddUserSchema = z.object({
    email: z.string().email(messageFormat),
    password: z.string().regex(regexPassword, messagePassword),
    confirmPassword: z.string().regex(regexPassword, messageConfirmPassword),
    nomSociete: z.string().min(1, { message: 'Le nom de société est obligatoire' }),
    siret: z.string().min(1, { message: 'est obligatoire' }),
    numTva: z.string().min(1, { message: 'Le numero de TVA est obligatoire' }),
    capital: z.string().min(1, { message: 'Le capital est obligatoire' }),
    telephone: z.string().regex(regexTelephone, messageTelephone),
    nomDirigeant: z.string().min(1, { message: 'Le nom du dirigeant est obligatoire' }),
    prenomDirigeant: z.string().min(1, { message: 'Le prénom du dirigeant est obligatoire' }),
    urlVosFactures: z.string().url(messageFormat),
    apiKeyVosFactures: z.string().min(1, { message: 'Le code API est obligatoire' }),
    tauxHoraireHt: z.string().regex(regexTauxHoraire, { message: 'Le taux horaire hors taxe est obligatoire' }),
    dateEditionKbis: z.string().min(1, { message: 'La date d\'édition du KBIS est obligatoire' }),
    creditAbonnement: z.string().min(1)
}).refine(data => data.password === data.confirmPassword, {
    message: "Les mots de passe ne sont pas identiques",
    path: ["confirmPassword"]
});

export type AddUserType = z.infer<typeof AddUserSchema>;

export type ErreurAddUserType = {
    error: boolean,
    detail: AddUserError
};