
import { z } from "zod";
import { UpdateUserError } from "./UpdateUserError";

export const UpdateUserSchema = z.object({
    id: z.number().min(1),
    nomSociete: z.string(),
    email: z.string().email(),
    nomDirigeant: z.string().min(1),
    prenomDirigeant: z.string().min(1),
    creditAbonnement: z.number().min(1),
    creditConsoDuMois: z.number(),
    creditConsoGlobal: z.number(),
    createdAt: z.string(),
    updateAt: z.string(),
    siret: z.string().min(1),
    numTva: z.string().min(1),
    capital: z.string().min(1),
    telephone: z.string().min(1),
    dateEditionKbis: z.string().min(1),
    dateExpireKbis: z.string().min(1),
    urlVosFactures:  z.string().url(),
    apiKeyVosFactures:  z.string().min(1),
    tauxHoraireHt: z.string().min(1)
});

export type UpdateUserType = z.infer<typeof UpdateUserSchema>;

export type ErreurUpdateUserType = {
    error: boolean,
    detail: UpdateUserError
};