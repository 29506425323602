import { ChangeEvent, FormEvent, useState } from "react";
import { BoxAjouterUtilisateur, BoxBtnAjouterUtilisateur, BoxFieldAjouterUtilisateur, BoxHeadingAjouterUtilisateur, FormAjouterUtilisateur, HeadingAjouterUtilisateur, SeparatorAjouterUtilisateur } from "./AjouterUtilisateurStyle";
import { useToasts } from "../../../contexts/toastContext/toastContext";
import { useTitlePage } from "../../../hooks/useTitlePage";
import { AddUserError } from "../../../core/validation/user/addUserValidation/AddUserError";
import { text } from "../../../shared/text/text";
import { addUserValidation } from "../../../core/validation/user/addUserValidation/AddUserValidation";
import { catchError } from "../../../core/request/catchError";
import BasePage from "../../../components/common/basePage/BasePage";
import InputCustom from "../../../components/common/inputCustom/InputCustom";
import Button from "../../../components/common/button/Button";
import { useAuth } from "../../../contexts/authContext/AuthContext";
import { filesValidation } from "../../../core/validation/user/addUserValidation/filesValidation/FilesValidation";
import { FileError } from "../../../core/validation/user/addUserValidation/filesValidation/FileError";
import { AddUserClass } from "../../../shared/class/utilisateur/addUser/AddUserClass";
import { useNavigate } from "react-router-dom";
import InputDocument from "../../../components/feature/utilisateur/ajouterUtilisateur/field/inputDocument/InputDocument";

const AjouterUtilisateur = (): JSX.Element => {
    const { token } = useAuth().isAuthenticated;
    const navigate = useNavigate();
    const { pushToast } = useToasts();
    const titlePage = useTitlePage(text.pages.ajouterUtilisateur.title);

    const [user, setUser] = useState<AddUserClass>(new AddUserClass());
    const [cachet, setCachet] = useState<File | undefined>(undefined);
    const [kbis, setKbis] = useState<File | undefined>(undefined);
    const [rib, setRib] = useState<File | undefined>(undefined);
    const [cni, setCni] = useState<File | undefined>(undefined);

    const [errorFile, setErrorFile] = useState<FileError>(new FileError());
    const [erreur, setErreur] = useState<AddUserError>(new AddUserError());
    const [loading, setLoading] = useState<boolean>(false);

    const resetForm = () => {
        setErreur(new AddUserError());
        setUser(new AddUserClass());
        setErrorFile(new FileError());
        setCachet(undefined);
        setKbis(undefined);
        setRib(undefined);
        setCni(undefined);
        navigate("/");
    };

    const handlechangeUser = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser((curr: AddUserClass) => {
            const updatedUser = new AddUserClass();
            Object.assign(updatedUser, curr, { [name]: value });

            return updatedUser;
        });
    };

    const submitCentre = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!token) return;

        //Faire la validation Zod
        const isValide = addUserValidation(user);

        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        const fileIsValide = filesValidation(cachet, kbis, rib, cni);

        setErrorFile({ ...errorFile, ...fileIsValide.detail });

        if (fileIsValide.error) return;

        user.convertCreditAbonnementToNumber();

        const formdata = new FormData();
        formdata.append('data', JSON.stringify(user));
        cachet && formdata.append('cachet', cachet);
        kbis && formdata.append('kbis', kbis);
        rib && formdata.append('rib', rib);
        cni && formdata.append('cni', cni);

        setLoading(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/register`,
            {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: formdata
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: "Le centre est bien enregistré", type: 'success', duration: 5 });
                navigate("/");
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    };

    return (
        <BasePage title={titlePage}>
            <BoxAjouterUtilisateur>
                <BoxHeadingAjouterUtilisateur>
                    <HeadingAjouterUtilisateur>{text.pages.ajouterUtilisateur.title}</HeadingAjouterUtilisateur>
                </BoxHeadingAjouterUtilisateur>

                <FormAjouterUtilisateur onSubmit={submitCentre} onReset={resetForm}>
                    <BoxFieldAjouterUtilisateur>
                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelEmail}
                            id="email"
                            type="email"
                            name="email"
                            value={user.email}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.email}
                        />

                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelPassword}
                            id="password"
                            type="password"
                            name="password"
                            value={user.password}
                            onChange={handlechangeUser}

                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.password}
                        />

                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelConfirmPassword}
                            id="confirmPassword"
                            type="password"
                            name="confirmPassword"
                            value={user.confirmPassword}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.confirmPassword}
                        />
                    </BoxFieldAjouterUtilisateur>

                    <BoxFieldAjouterUtilisateur>
                        <InputCustom
                            width="66"
                            label={text.pages.ajouterUtilisateur.labelNomSociete}
                            id="nomSociete"
                            type="text"
                            name="nomSociete"
                            value={user.nomSociete}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.nomSociete}
                        />

                    </BoxFieldAjouterUtilisateur>

                    <BoxFieldAjouterUtilisateur>
                        <InputCustom
                            width="23.5"
                            label={text.pages.ajouterUtilisateur.labelSiret}
                            id="siret"
                            type="text"
                            name="siret"
                            value={user.siret}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.siret}
                        />

                        <InputCustom
                            width="23.5"
                            label={text.pages.ajouterUtilisateur.labelNumTva}
                            id="numTva"
                            type="text"
                            name="numTva"
                            value={user.numTva}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.numTva}
                        />

                        <InputCustom
                            width="23.5"
                            label={text.pages.ajouterUtilisateur.labelCapital}
                            id="capital"
                            type="text"
                            name="capital"
                            value={user.capital}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.capital}
                        />

                        <InputCustom
                            width="23.5"
                            label={text.pages.ajouterUtilisateur.labelTelephone}
                            id="telephone"
                            type="text"
                            name="telephone"
                            value={user.telephone}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.telephone}
                        />
                    </BoxFieldAjouterUtilisateur>

                    <BoxFieldAjouterUtilisateur>
                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelNomDirigeant}
                            id="nomDirigeant"
                            type="text"
                            name="nomDirigeant"
                            value={user.nomDirigeant}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.nomDirigeant}
                        />

                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelPrenomDirigeant}
                            id="prenomDirigeant"
                            type="text"
                            name="prenomDirigeant"
                            value={user.prenomDirigeant}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.prenomDirigeant}
                        />

                        <InputDocument
                            id="cni"
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelFileCni}
                            setFile={setCni}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            invalid={errorFile.cni}
                            setError={setErrorFile}
                        />
                    </BoxFieldAjouterUtilisateur>


                    <BoxFieldAjouterUtilisateur>
                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelUrlVosFacture}
                            id="urlVosFactures"
                            type="url"
                            name="urlVosFactures"
                            value={user.urlVosFactures}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.urlVosFactures}
                        />

                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelCodeApiVosFacture}
                            id="apiKeyVosFactures"
                            type="text"
                            name="apiKeyVosFactures"
                            value={user.apiKeyVosFactures}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.apiKeyVosFactures}
                        />

                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelTauxHoraireHt}
                            id="tauxHoraireHt"
                            type="text"
                            name="tauxHoraireHt"
                            value={user.tauxHoraireHt}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.tauxHoraireHt}
                            pattern="[0-9]+([.][0-9]{0,2})?"
                        />
                    </BoxFieldAjouterUtilisateur>

                    <BoxFieldAjouterUtilisateur>
                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelDateEditionKbis}
                            id="dateEditionKbis"
                            type="date"
                            name="dateEditionKbis"
                            value={user.dateEditionKbis}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.dateEditionKbis}
                        />

                        <InputDocument
                            id="kbis"
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelFileKbis}
                            setFile={setKbis}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            invalid={errorFile.kbis}
                            setError={setErrorFile}
                        />

                        <div style={{ width: '32%' }}></div>

                    </BoxFieldAjouterUtilisateur>

                    <BoxFieldAjouterUtilisateur>
                        <InputDocument
                            id="cachet"
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelFileCachet}
                            setFile={setCachet}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            invalid={errorFile.cachet}
                            setError={setErrorFile}
                        />

                        <InputDocument
                            id="rib"
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelFileRib}
                            setFile={setRib}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            invalid={errorFile.rib}
                            setError={setErrorFile}
                        />

                        <InputCustom
                            width="32"
                            label={text.pages.ajouterUtilisateur.labelcreditAbonnement}
                            id="creditAbonnement"
                            type="number"
                            name="creditAbonnement"
                            value={user.creditAbonnement}
                            onChange={handlechangeUser}
                            //contentTooltip={<NomDeSocieteToolContent />}
                            disabled={false}
                            invalid={erreur.creditAbonnement}
                        />
                    </BoxFieldAjouterUtilisateur>

                    <BoxBtnAjouterUtilisateur>
                        <Button
                            color="alert"
                            width="150px"
                            type="reset"
                            label={text.pages.ajouterUtilisateur.labelBtnReset}
                            loader={false}
                            disabled={loading}
                        />

                        <SeparatorAjouterUtilisateur />

                        <Button
                            width="150px"
                            type="submit"
                            label={text.pages.ajouterUtilisateur.labelBtnCreer}
                            loader={loading}
                            disabled={loading}
                        />
                    </BoxBtnAjouterUtilisateur>
                </FormAjouterUtilisateur>
            </BoxAjouterUtilisateur>
        </BasePage>
    );
};

export default AjouterUtilisateur;