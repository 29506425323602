export class AddProduitError {
    name: boolean;
    tax: boolean;
    code: boolean;
    totalPriceHt: boolean;
    quantity: boolean;
    invoiceIntegration: boolean;

    constructor() {
        this.name = false;
        this.tax = false;
        this.code = false;
        this.totalPriceHt = false;
        this.quantity = false;
        this.invoiceIntegration = false;
    };
};