export class AddUserError {
    email: boolean;
    password: boolean;
    confirmPassword: boolean;
    nomSociete: boolean;
    siret: boolean;
    numTva: boolean;
    capital: boolean;
    telephone: boolean;
    nomDirigeant: boolean;
    prenomDirigeant: boolean;
    urlVosFactures: boolean;
    apiKeyVosFactures: boolean;
    tauxHoraireHt: boolean;
    dateEditionKbis: boolean;
    creditAbonnement: boolean;
    cni: boolean;
    kbis: boolean;
    cachet: boolean;
    rib: boolean;

    constructor() {
        this.email = false;
        this.password = false;
        this.confirmPassword = false;
        this.nomSociete = false;
        this.siret = false;
        this.numTva = false;
        this.capital = false;
        this.telephone = false;
        this.nomDirigeant = false;
        this.prenomDirigeant = false;
        this.urlVosFactures = false;
        this.apiKeyVosFactures = false;
        this.tauxHoraireHt = false;
        this.dateEditionKbis = false;
        this.creditAbonnement = false;
        this.cni = false;
        this.kbis = false;
        this.cachet = false;
        this.rib = false;
    };
};