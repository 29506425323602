import { styled } from "styled-components";
import { theme } from "../../../theme/theme";

type TextareaProps = {
    $invalid?: boolean
};

export const Textarea = styled.textarea<TextareaProps>`
    width: 100%;
    height: 200px;
    border: solid 1px ${theme.color.grayBorder};
    border-radius: ${theme.css.radius};
    background-color: ${theme.color.white};
    padding: 10px;
    font-family: Montserrat-Regular;
    font-size: ${theme.font.regular};
    resize : none;
    outline: none;
    ${props => props.$invalid && `border: solid 1px ${theme.color.alert};`}
    &: focus{
        border: solid 1px ${theme.color.primary};
    };
`;