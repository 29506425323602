import styled from "styled-components";
import { theme } from "../../../../../../../theme/theme";

export const BoxTabAbonnement = styled.div`
    width: 100%; 
    min-height: 390px; 
    padding: 15px 25px; 
    background-color: #f4f4f4;
`;

export const BoxFieldTabAbonnement = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;
   