import { ChangeEvent } from "react";
import { BoxInputDoc, BoxLabelDocTooltips, InputDocumentCustom, LabelDoc } from "./InputDocumentStyle";
import { InputDocumentProps } from "./InputDocumentType";
import { FileError } from "../../../../../../core/validation/user/addUserValidation/filesValidation/FileError";
import Tooltips from "../../../../../common/tooltips/Tooltips";

const InputDocument = ({ width, label, id, setFile, invalid, contentTooltip, setError }: InputDocumentProps): JSX.Element => {

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files[0] && e.target.files[0].size >= 67000000) {
            setError((error: FileError) => {
                const err = { ...error, [id]: true };
                return err;
            });

            return;
        };

        e.target.files && e.target.files[0] && setFile(e.target.files[0]);
    }


    return (
        <BoxInputDoc $width={width}>
            {label &&
                <BoxLabelDocTooltips>
                    <LabelDoc htmlFor={id}>{label}</LabelDoc>
                    {contentTooltip && <Tooltips id={id + id} content={contentTooltip} />}
                </BoxLabelDocTooltips>
            }

            <InputDocumentCustom $invalid={invalid} type="file" onChange={handleChangeFile} id={id} name={id} size={67000000} />
        </BoxInputDoc>
    );
};

export default InputDocument;