import { text } from "../../../../../../../shared/text/text";
import InputCustom from "../../../../../../common/inputCustom/InputCustom";
import { BoxFieldTabAbonnement, BoxTabAbonnement } from "./TabAbonnementStyle";
import { TabAbonnementProps } from "./TabAbonnementType";

const TabAbonnement = ({ indexSelected, user, erreur, isEdit, changeUser }: TabAbonnementProps): JSX.Element | null => {
    if (indexSelected === '1') {
        return (
            <BoxTabAbonnement>
                <BoxFieldTabAbonnement>
                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelcreditAbonnement}
                        id="creditAbonnement"
                        type="number"
                        name="creditAbonnement"
                        value={user.creditAbonnement}
                        onChange={changeUser}
                        disabled={!isEdit}
                        invalid={erreur.creditAbonnement}
                    />

                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelcreditConsoDuMois}
                        id="creditConsoDuMois"
                        type="number"
                        name="creditConsoDuMois"
                        value={user.creditConsoDuMois}
                        onChange={changeUser}
                        disabled={true}
                        invalid={erreur.creditConsoDuMois}
                    />

                    <InputCustom
                        width="32"
                        label={text.pages.utilisateur.labelcreditConsoGlobal}
                        id="creditConsoGlobal"
                        type="number"
                        name="creditConsoGlobal"
                        value={user.creditConsoGlobal}
                        onChange={changeUser}
                        disabled={true}
                        invalid={erreur.creditConsoGlobal}
                    />

                </BoxFieldTabAbonnement>
            </BoxTabAbonnement>
        );
    };

    return null;
};

export default TabAbonnement;