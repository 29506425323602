export class AddAssuranceError {
    nomSociete: boolean;
    service: boolean;
    adresse: boolean;
    complementAdresse: boolean;
    ville: boolean;
    codePostal: boolean;
    email: boolean;

    constructor() {
        this.nomSociete = false;
        this.service = false;
        this.adresse = false;
        this.complementAdresse = false;
        this.ville = false;
        this.codePostal = false;
        this.email = false;
    };
};