import { z } from "zod";

export const UserApiSchema = z.object({
    id: z.number(),
    nomSociete: z.string(),
    email: z.string().email(),
    nomDirigeant: z.string(),
    prenomDirigeant: z.string(),
    creditAbonnement: z.number(),
    creditConsoDuMois: z.number(),
    creditConsoGlobal: z.number(),
    createdAt: z.string(),
    updateAt: z.string(),
    siret: z.string(),
    numTva: z.string(),
    capital: z.string(),
    telephone: z.string(),
    dateEditionKbis: z.string(),
    dateExpireKbis: z.string(),
    urlVosFactures:  z.string().url(),
    apiKeyVosFactures:  z.string(),
    tauxHoraireHt: z.string(),
    isActif: z.boolean()
});

export const TableUserSchema = UserApiSchema.array();

const PaginationSchema = z.object({
    currentPage: z.number(),
    limit: z.number(),
    param: z.string(),
    paramValue: z.string(),
    totalPages: z.number(),
    totalItems: z.number()
});

export const UserResultSchema = z.object({
    data: UserApiSchema.array(),
    paramPagination : PaginationSchema
});

export const LoginResponseSchema = z.object({
    token: z.string(),
    refresh_token: z.string()
});

export type UserApiType = z.infer<typeof UserApiSchema>;
export type UserResultType = z.infer<typeof UserResultSchema>;