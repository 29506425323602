
import { formDataToObject } from "../../mapper/formDataToObject";
import { SendEmailError } from "./SendEmailError";
import { ErreurSendEmailType, SendEmailSchema, SendEmailType} from "./sendEmailValidationType";

export const sendEmailValidation = (data: FormData): ErreurSendEmailType => {

    const formPayload = formDataToObject<SendEmailType>(data);

    const erreurPage = new SendEmailError();

    const erreurAddUserFinal: ErreurSendEmailType = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = SendEmailSchema.safeParse(formPayload);

    if (!schemaValidation.success) {
        Object.assign(erreurAddUserFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: true });
        });

        return erreurAddUserFinal;
    };

    return erreurAddUserFinal;
};