export class SendEmailError {
    nom: boolean;
    from: boolean;
    to: boolean;
    objet: boolean;
    message: boolean;

    constructor() {
        this.nom = false;
        this.from = false;
        this.to = false;
        this.objet = false;
        this.message = false;
    };
};