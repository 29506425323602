import { HashRouter, Routes, Route } from 'react-router-dom';
import Login from '../pages/login/Login';
import AuthProvider from '../contexts/authContext/AuthContext';
import GlobalStyle from '../styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme/theme';
import AuthRoute from '../components/common/authentification/RouteAuth';
import ToastProvider from '../contexts/toastContext/toastContext';
import ListeProduit from '../pages/produit/ListeProduit';
import ListeUtilisateur from '../pages/utilisateur/listeUtilisateur/ListeUtilisateur';
import AjouterUtilisateur from '../pages/utilisateur/ajouterUtilisateur/AjouterUtilisateur';
import ListeAssurance from '../pages/assurance/listeAssurance/ListeAssurance';

const Router = () => {
    return (
        <HashRouter>
            <ThemeProvider theme={theme}>
                <ToastProvider>
                    <AuthProvider>
                        <GlobalStyle />
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={< AuthRoute Component={ListeUtilisateur} />} />
                            <Route path="/utilisateur/ajouter" element={< AuthRoute Component={AjouterUtilisateur} />} />
                            <Route path="/produit" element={< AuthRoute Component={ListeProduit} />} />
                            <Route path="/assurance" element={< AuthRoute Component={ListeAssurance} />} />
                        </Routes>
                    </AuthProvider>
                </ToastProvider>
            </ThemeProvider>
        </HashRouter>
    );
};

export default Router; 
