import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import Portal from '../../../common/portal/Portal';
import { useToasts } from '../../../../contexts/toastContext/toastContext';
import { text } from '../../../../shared/text/text';
import { TabAction } from './tabs/tabAction/TabAction';
import { ModaleProduitProps } from './ModaleProduitType';
import { ProduitApiType, ProduitSchema } from '../../../../pages/produit/ListeProduitType';
import { UpdateProduitError } from '../../../../core/validation/produit/updateProduitValidation/UpdateProduitError';
import { BoxFieldTabProduit, BoxTabProduit, FormVoirProduit, PenCustom } from './ModaleProduitStyle';
import { updateProduitValidation } from '../../../../core/validation/produit/updateProduitValidation/updateProduitValidation';
import InputCustom from '../../../common/inputCustom/InputCustom';
import SelectCustom from '../../../common/selectCustom/SelectCustom';
import { optionTax } from '../../../../shared/optionSelect/optionTax';
import Checkbox from '../../../common/checkbox/Checkbox';
import { catchError } from '../../../../core/request/catchError';

const ModaleProduit = ({ produit, setProduits }: ModaleProduitProps): JSX.Element | null => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [produitData, setProduitData] = useState<ProduitApiType>(produit);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [erreur, setErreur] = useState<UpdateProduitError>(new UpdateProduitError());

    const openModale = () => {

        if (!ProduitSchema.safeParse(produit).success) {
            pushToast({ content: text.components.modaleProduit.msgErrDisplay, type: "alert", duration: 5 });
            return;
        };

        setOpen(() => true);
    };

    const closeModale = () => {
        setIsEdit(() => false);
        setErreur(() => new UpdateProduitError());
        setOpen(() => false);
    };

    const handleChangeProduit = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, type, value } = e.target;

        setProduitData((curr: ProduitApiType) => {
            if (e.target instanceof HTMLInputElement && type === "checkbox" && name === "invoiceIntegration") {
                const { checked } = e.target;

                return { ...curr, [name]: checked }
            };

            return { ...curr, [name]: value }
        });
    };

    const modifierUnProduit = async (e: FormEvent) => {
        e.preventDefault();

        const isValide = updateProduitValidation(produitData);
        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        if (!token) return;

        setLoadingUpdate(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/facture/produit/${produit.id}`, {
            method: "PATCH",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(produitData)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            catchError(data);

            setProduits((curr: ProduitApiType[]) => curr.map((item) => item.id === data.id ? data : item));
            setIsEdit(() => false);
            pushToast({ content: text.components.modaleProduit.msgSuccessCreate, type: "success", duration: 5 }); 
        })
        .catch((error: Error) => {
            pushToast({ content: error.message, type: "alert", duration: 5 }); 
        })
        .finally(() => {
            setLoadingUpdate(() => false);
        });
    };

    const annule = (e: FormEvent) => {
        e.preventDefault();
        setIsEdit(() => false);
        setErreur(() => new UpdateProduitError());
        setProduitData(() => produit);
    };

    return (
        <>
            <PenCustom onClick={openModale} />
            <Portal
                displayHead={true}
                title={text.components.modaleProduit.titlePortal}
                open={open}
                height="fit-content"
                onClick={closeModale}
            >
                <FormVoirProduit onSubmit={modifierUnProduit}>
                    <BoxTabProduit>
                        <BoxFieldTabProduit>
                            <InputCustom
                                width="49"
                                label={text.components.modaleProduit.labelNomProduit}
                                id="name"
                                type="text"
                                name="name"
                                value={produitData.name}
                                onChange={(e) => handleChangeProduit(e)}
                                disabled={!isEdit}
                                invalid={erreur.name}
                            />

                            <InputCustom
                                width="49"
                                label={text.components.modaleProduit.labelCodeProduit}
                                id="code"
                                type="text"
                                name="code"
                                value={produitData.code}
                                onChange={handleChangeProduit}
                                disabled={!isEdit}
                                invalid={erreur.code}
                            />
                        </BoxFieldTabProduit>

                        <BoxFieldTabProduit>
                            <InputCustom
                                width="32"
                                label={text.components.modaleProduit.labelTarifHt}
                                id="totalPriceHt"
                                type="text"
                                name="totalPriceHt"
                                value={produitData.totalPriceHt}
                                onChange={handleChangeProduit}
                                disabled={!isEdit}
                                invalid={erreur.totalPriceHt}
                            />

                            <InputCustom
                                width="32"
                                label={text.components.modaleProduit.labelQuantity}
                                id="quantity"
                                type="number"
                                name="quantity"
                                value={produitData.quantity}
                                onChange={handleChangeProduit}
                                disabled={!isEdit}
                                invalid={erreur.quantity}
                            />

                            <SelectCustom
                                label={text.components.modaleProduit.labeltax}
                                id="tax"
                                value={produitData.tax}
                                options={optionTax}
                                onChange={handleChangeProduit}
                                disabled={!isEdit}
                                invalid={erreur.tax}
                            />
                        </BoxFieldTabProduit>

                        <BoxFieldTabProduit>
                            <Checkbox
                                label={text.components.modaleProduit.labelIntegration}
                                mb={20}
                                id="invoiceIntegration"
                                name="invoiceIntegration"
                                checked={produitData.invoiceIntegration}
                                onChange={handleChangeProduit}
                                reverse={true}
                                disabled={!isEdit}
                            />
                        </BoxFieldTabProduit>
                    </BoxTabProduit>

                    <TabAction
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        loading={loadingUpdate}
                        annule={annule}
                    />
                </FormVoirProduit>
            </Portal>
        </>
    );
};

export default ModaleProduit;