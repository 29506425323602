export class UpdateUserError {
    id: boolean;
    nomSociete: boolean;
    email: boolean;
    nomDirigeant: boolean;
    prenomDirigeant: boolean;
    creditAbonnement: boolean;
    creditConsoDuMois: boolean;
    creditConsoGlobal: boolean;
    createdAt: boolean;
    updateAt: boolean;
    siret: boolean;
    numTva: boolean;
    capital: boolean;
    telephone: boolean;
    dateEditionKbis: boolean;
    dateExpireKbis: boolean;
    urlVosFactures: boolean;
    apiKeyVosFactures: boolean;
    tauxHoraireHt: boolean;

    constructor() {
        this.id = false;
        this.nomSociete = false;
        this.email = false;
        this.nomDirigeant = false;
        this.prenomDirigeant = false;
        this.creditAbonnement = false;
        this.creditConsoDuMois = false;
        this.creditConsoGlobal = false;
        this.createdAt = false;
        this.updateAt = false;
        this.siret = false;
        this.numTva = false;
        this.capital = false;
        this.telephone = false;
        this.dateEditionKbis = false;
        this.dateExpireKbis = false;
        this.urlVosFactures = false;
        this.apiKeyVosFactures = false;
        this.tauxHoraireHt = false;
    };
};