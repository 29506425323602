import { styled } from 'styled-components';
import { theme } from '../../../../theme/theme';

export const BoxTabsAddAssurance = styled.div`
    width: 100%; 
    background-color: ${theme.color.white};
    height: 40px; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
`;

export const FormAddAssurance = styled.form`
    width: 1150px;
    height: fit-content; 
    max-height: 90vh; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 25px;
    @media screen and (${theme.screen.tablette}) {
        width: 90vw;
    };
`;

export const BoxTabAddAssurance = styled.div`
    width: 100%; 
    min-height: fit-content; 
    padding: 15px 25px; 
    background-color: #f4f4f4;
`;

export const BoxFieldTabAddAssurance = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;

export const BoxTypeAddAssurance = styled.div`
    width: 100%;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const SeparatorSelectAddAssurance = styled.div`
    width: 20px;
`;

export const BoxNomEmailAddAssurance = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (${theme.screen.tablette}) {
       &:first-child {
            margin-bottom: 50px;
       }
    };
`;

export const BoxBtnModifierAddAssurance = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const FormVoirAddAssuranceHeading = styled.h3`
    font-size: ${theme.font.medium};
    color:  ${theme.color.primary};
`;

export const BoxBtnActionAddAssurance = styled.div`
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px ${theme.color.headerTable};
`;

export const SeparatoBtnActionAddAssurance = styled.div`
    width: 20px;
`;