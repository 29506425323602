import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import Portal from '../../../common/portal/Portal';
import { useToasts } from '../../../../contexts/toastContext/toastContext';
import { text } from '../../../../shared/text/text';
import { TabAction } from './tabs/tabAction/TabAction';
import { BoxFieldTabProduit, BoxTabProduit, FormVoirProduit } from './ModaleAssuranceStyle';
import InputCustom from '../../../common/inputCustom/InputCustom';
import { catchError } from '../../../../core/request/catchError';
import { ModaleAssuranceProps } from './ModaleAssuranceType';
import { AssuranceApiType, AssuranceSchema } from '../../../../pages/assurance/listeAssurance/ListeAssuranceType';
import { UpdateAssuranceError } from '../../../../core/validation/assurance/updateAssuranceValidation/UpdateAssuranceError';
import { IconPenCustom } from '../../../common/tableau/IconsTable/IconsTable';
import { updateAssuranceValidation } from '../../../../core/validation/assurance/updateAssuranceValidation/updateAssuranceValidation';

const ModaleAssuance = ({ assurance, setAssurances }: ModaleAssuranceProps): JSX.Element | null => {
    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [assuranceData, setAssuranceData] = useState<AssuranceApiType>(assurance);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [erreur, setErreur] = useState<UpdateAssuranceError>(new UpdateAssuranceError());

    const openModale = () => {

        if (!AssuranceSchema.safeParse(assurance).success) {
            pushToast({ content: text.components.modaleProduit.msgErrDisplay, type: "alert", duration: 5 });
            return;
        };

        setOpen(() => true);
    };

    const closeModale = () => {
        setIsEdit(() => false);
        setErreur(() => new UpdateAssuranceError());
        setOpen(() => false);
    };

    const handleChangeAssurance = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        setAssuranceData((curr: AssuranceApiType) => {
            return { ...curr, [name]: value }
        });
    };

    const modifierUneAssurance = async (e: FormEvent) => {
        e.preventDefault();

        const isValide = updateAssuranceValidation(assuranceData);

        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        if (!token) return;

        setLoadingUpdate(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/assurance/coordonnee/${assurance.id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(assuranceData)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                setAssurances((curr: AssuranceApiType[]) => curr.map((item) => item.id === data.id ? data : item));
                setIsEdit(() => false);
                pushToast({ content: text.components.modaleAssurance.msgSuccessUpdate, type: "success", duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoadingUpdate(() => false);
            });
    };

    const annule = (e: FormEvent) => {
        e.preventDefault();
        setIsEdit(() => false);
        setErreur(() => new UpdateAssuranceError());
        setAssuranceData(() => assurance);
    };

    return (
        <>
            <IconPenCustom onClick={openModale} />

            <Portal
                displayHead={true}
                title={text.components.modaleAssurance.titlePortal}
                open={open}
                height="fit-content"
                onClick={closeModale}
            >
                <FormVoirProduit onSubmit={modifierUneAssurance}>
                    <BoxTabProduit>
                        <BoxFieldTabProduit>
                            <InputCustom
                                width="49"
                                label={text.components.modaleAssurance.labelNomSciete}
                                id="nomSociete"
                                type="text"
                                name="nomSociete"
                                value={assuranceData.nomSociete}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.nomSociete}
                            />

                            <InputCustom
                                width="49"
                                label={text.components.modaleAssurance.labelEmail}
                                id="email"
                                type="text"
                                name="email"
                                value={assuranceData.email}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.email}
                            />
                        </BoxFieldTabProduit>

                        <BoxFieldTabProduit>
                            <InputCustom
                                width="49"
                                label={text.components.modaleAssurance.labelService}
                                id="service"
                                type="text"
                                name="service"
                                value={assuranceData.service}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.service}
                            />

                            <InputCustom
                                width="49"
                                label={text.components.modaleAssurance.labelAdresse}
                                id="adresse"
                                type="text"
                                name="adresse"
                                value={assuranceData.adresse}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.adresse}
                            />
                        </BoxFieldTabProduit>

                        <BoxFieldTabProduit>
                            <InputCustom
                                width="32"
                                label={text.components.modaleAssurance.labelComplAdresse}
                                id="complementAdresse"
                                type="text"
                                name="complementAdresse"
                                value={assuranceData.complementAdresse}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.complementAdresse}
                            />

                            <InputCustom
                                width="32"
                                label={text.components.modaleAssurance.labelVille}
                                id="ville"
                                type="text"
                                name="ville"
                                value={assuranceData.ville}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.ville}
                            />

                            <InputCustom
                                width="32"
                                label={text.components.modaleAssurance.labelCodePostal}
                                id="codePostal"
                                type="text"
                                name="codePostal"
                                value={assuranceData.codePostal}
                                onChange={handleChangeAssurance}
                                disabled={!isEdit}
                                invalid={erreur.codePostal}
                            />
                        </BoxFieldTabProduit>
                    </BoxTabProduit>

                    <TabAction
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        loading={loadingUpdate}
                        annule={annule}
                    />
                </FormVoirProduit>
            </Portal>
        </>
    );
};

export default ModaleAssuance;