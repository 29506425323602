import { FormEvent, useState } from 'react';
import { BoxBtnAddressEmail, BoxEmetteurDestinatiare, BoxEmetteurEnvoyerUnEmail, BoxFieldAddressEmail, BoxPjAddressEmail, FormEnvoyerUnEmail, ParagEmailItalic, ParagLabelAddressEmail, ParagValieAddressEmail, SeparatorBtnAddressEmail } from './ModalEnvoyerUnEmailStyle';
import { ModalEnvoyerUnEmailProps } from './ModalEnvoyerUnEmailType';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import { useToasts } from '../../../../../contexts/toastContext/toastContext';
import { SendEmailError } from '../../../../../core/validation/sendEmailValidation/SendEmailError';
import { sendEmailValidation } from '../../../../../core/validation/sendEmailValidation/sendEmailValidation';
import { catchError } from '../../../../../core/request/catchError';
import Portal from '../../../../common/portal/Portal';
import { text } from '../../../../../shared/text/text';
import InputCustom from '../../../../common/inputCustom/InputCustom';
import { Textarea } from '../../../../common/textarea/TextArea';
import InputFile from '../../../../common/inputFile/InputFile';
import Button from '../../../../common/button/Button';
import { IconEnvelopeCustom } from '../../../../common/tableau/IconsTable/IconsTable';

const ModalEnvoyerUnEmail = ({ user }: ModalEnvoyerUnEmailProps): JSX.Element => {
    const { token, nom, username } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [loaderBtn, setLoaderBtn] = useState<boolean>(false);

    const [erreur, setErreur] = useState<SendEmailError>(new SendEmailError());

    const closeModal = () => {
        setErreur(() => new SendEmailError());
        setOpen(() => false);
        setLoaderBtn(() => false);
    };

    const envoyerUnEmail = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!user || !username || !token) {
            return;
        };

        const form = e.currentTarget;
        const formdata = new FormData(form);
        formdata.append('nom', user.nomSociete);
        formdata.append('from', username);
        formdata.append('to', user.email);

        const isValide = sendEmailValidation(formdata);

        setErreur({ ...erreur, ...isValide.detail });

        if (isValide.error) return;

        setLoaderBtn(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/communication/email`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}` },
            body: formdata
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: data.message, type: "success", duration: 5 });
                setOpen(() => false);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: "alert", duration: 5 });
            })
            .finally(() => {
                setLoaderBtn(() => false);
            });
    };

    return (
        <>
            <IconEnvelopeCustom onClick={() => setOpen(() => true)} />

            {user &&
                <Portal
                    displayHead={true}
                    title={text.components.modalEnvoyerUnEmail.titlePortal}
                    open={open}
                    height="fit-content"
                    onClick={closeModal}
                >
                    <FormEnvoyerUnEmail onSubmit={envoyerUnEmail}>
                        <BoxEmetteurEnvoyerUnEmail>
                            <BoxEmetteurDestinatiare>
                                <ParagLabelAddressEmail>{text.components.modalEnvoyerUnEmail.labelEmetteur}</ParagLabelAddressEmail>
                                <ParagValieAddressEmail>{nom}</ParagValieAddressEmail>
                                <ParagEmailItalic>{username}</ParagEmailItalic>
                            </BoxEmetteurDestinatiare>

                            <BoxEmetteurDestinatiare>
                                <ParagLabelAddressEmail>{text.components.modalEnvoyerUnEmail.labelDestinataire}</ParagLabelAddressEmail>
                                <ParagValieAddressEmail>{user.nomSociete}</ParagValieAddressEmail>
                                <ParagEmailItalic>{user.email}</ParagEmailItalic>
                            </BoxEmetteurDestinatiare>
                        </BoxEmetteurEnvoyerUnEmail>

                        <BoxFieldAddressEmail>
                            <InputCustom
                                id="objet"
                                type="text"
                                name="objet"
                                placeholder={text.components.modalEnvoyerUnEmail.placeholderObjetEmail}
                                invalid={erreur.objet}
                                required
                            />

                            <Textarea
                                id="message"
                                name="message"
                                rows={9}
                                $invalid={erreur.message}
                                required
                            />
                        </BoxFieldAddressEmail>

                        <BoxPjAddressEmail>
                            <InputFile
                                label={text.components.modalEnvoyerUnEmail.labelPieceJointe}
                                name="pj[]"
                            />
                        </BoxPjAddressEmail>

                        <BoxBtnAddressEmail>
                            <Button
                                width="90px"
                                type="button"
                                color="alert"
                                label={text.components.modalEnvoyerUnEmail.labelBtnAnnuler}
                                loader={false}
                                disabled={false}
                                onClick={closeModal}
                            />

                            <SeparatorBtnAddressEmail />

                            <Button
                                width="90px"
                                type="submit"
                                label={text.components.modalEnvoyerUnEmail.labelBtnEnvoyer}
                                loader={loaderBtn}
                                disabled={loaderBtn}
                            />
                        </BoxBtnAddressEmail>
                    </FormEnvoyerUnEmail>
                </Portal>
            }
        </>
    );
};

export default ModalEnvoyerUnEmail;