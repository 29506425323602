
import { z } from "zod";
import { UpdateProduitError } from "./UpdateProduitError";

export const UpdateProduitSchema = z.object({
    id: z.number().min(1),
    name: z.string().min(1),
    tax: z.string().min(1),
    code: z.string().min(1),
    totalPriceGross: z.string().min(1),
    totalPriceHt:z.string().min(1),
    quantity:z.string().min(1),
    createdAt:z.string().min(1),
    updateAt:z.string().min(1),
    invoiceIntegration: z.boolean()
});

export type UpdateProduitType = z.infer<typeof UpdateProduitSchema>;

export type ErreurUpdateProduitType = {
    error: boolean,
    detail: UpdateProduitError
};