import { useEffect, useState } from "react";
import BasePage from "../../components/common/basePage/BasePage";
import { useTitlePage } from "../../hooks/useTitlePage";
import { text } from "../../shared/text/text";
import { ProduitApiType } from "./ListeProduitType";
import { BoxHeadingListeUtilisateur, BoxListeUtilisateur, HeadingListeUtilisateur } from "./ListeProduitStyle";
import TableauProduit from "../../components/feature/produit/listeProduit/TableauProduit";
import ModaleAjouterProduit from "../../components/feature/produit/modaleAjouterProduit/ModaleAjouterProduit";
import { useAuth } from "../../contexts/authContext/AuthContext";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { catchError } from "../../core/request/catchError";
import { RetourApiErreurSchema } from "../../types/RetourApiErreur";

const ListeProduit = (): JSX.Element => {

    const { token } = useAuth().isAuthenticated;
    const { pushToast } = useToasts();
    const titlePage = useTitlePage(text.pages.listeUtilisateur.title);

    const [produits, setProduits] = useState<ProduitApiType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!token) return;

        setLoading(() => true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/administrateur/facture/produit`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                setProduits(() => data);
            })
            .catch((error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    }, [pushToast, token]);


    return (
        <BasePage title={titlePage}>
            <BoxListeUtilisateur>
                <BoxHeadingListeUtilisateur>
                    <HeadingListeUtilisateur>{text.pages.listeProduit.title}</HeadingListeUtilisateur>

                    <ModaleAjouterProduit setProduits={setProduits}/>
                </BoxHeadingListeUtilisateur>

                <TableauProduit
                    loading={loading}
                    produits={produits}
                    setProduits={setProduits}
                />
            </BoxListeUtilisateur>
        </BasePage >

    );
};

export default ListeProduit;