import { BoxInput, BoxLabelTooltips, Input, Label } from "./InputCustomStyle";
import { InputCustomProps } from "./InputCustomType";
import Tooltips from "../tooltips/Tooltips";

const InputCustom = ({ id, type, name, width, label, placeholder, value, disabled, required, contentTooltip, invalid, onChange, pattern }: InputCustomProps) => {
    return (
        <BoxInput $width={width}>
            {label &&
                <BoxLabelTooltips>
                    <Label htmlFor={id}>{label}</Label>
                    {contentTooltip && <Tooltips id={id + id} content={contentTooltip}/>}
                </BoxLabelTooltips>
            }
            <Input
                id={id}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                $invalid={invalid}
                required={required}
                pattern={pattern}
                min={1}
            />
        </BoxInput>
    );
};

export default InputCustom;